import React, { ChangeEvent } from "react";
import * as S from "./styles";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  TextField,
  InputAdornment,
  IconButton,
  Button
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { 
  DataGrid, 
  GridCell, 
  GridRenderCellParams, 
  GridRenderEditCellParams, 
  GridToolbarColumnsButton, 
  GridToolbarContainer, 
  GridToolbarExport,
  useGridApiContext
} from "@mui/x-data-grid";
import Select from "../../components/select";
import { ProjectsService, updateProjectDescription } from "../../services/projects/projects.service";
import { StagesService } from "../../services/stages/stages.service";
import { useCategories } from "../../context/categories";
import { useStages } from "../../context/stages";
import { Projects } from "../../services/projects/projects.interface";
import { ProductDetailsService } from "../../services/product-details/product-details.service";
import { buildKey, checkProductsReleases, getCycle } from "./utils";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "i18next";
import { sanitizeProductVersion } from "../../services/product-details/utils";
import RefreshIcon from '@mui/icons-material/Refresh';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { gridStyles, combineStyles } from './styles';

type ColorRow = Record<string, "success" | "warning" | "error" | undefined>

type Column = {
  field: string;
  headerName: string;
  minWidth: number;
  width?: number;
  maxWidth?: number;
  flex?: number;
  editable: boolean;
  headerAlign?: 'left' | 'center' | 'right';
  align?: 'left' | 'center' | 'right';
  sortComparator?: (v1: string, v2: string) => number;
  renderCell: (params: GridRenderCellParams) => JSX.Element;
}

type Row = { [key: string]: string }

const initialState = {
  currentCategory: '',
  currentState: ''
};

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_CATEGORY':
      return { ...state, currentCategory: action.payload };
    case 'SET_STAGE':
      return { ...state, currentStage: action.payload };
    default:
      throw new Error();
  }
}

const Loading = () => (
  <Box width="100%" display="flex" style={{ position: 'absolute', top: "50%" }}>
    <CircularProgress style={{ margin: "0 auto" }} />
  </Box>
)

const CustomToolbar = () => {
  const { t } = useTranslation();
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarExport 
        csvOptions={{
          delimiter: ',',
          utf8WithBom: true,
          getRowsToExport: (params) => {
            // Get all visible rows
            const rows = params.apiRef.current.getRowModels();
            // Process the data before export
            rows.forEach((row) => {
              Object.keys(row).forEach(key => {
                if (typeof row[key] === 'string' && row[key].includes('|')) {
                  row[key] = row[key].split('|')[1];
                }
              });
            });
            // Return array of row IDs
            return Array.from(rows.keys());
          }
        }}
        printOptions={{ 
          disableToolbarButton: true
        }}
      />
    </GridToolbarContainer>
  );
};

const CustomFooter = () => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const rowCount = apiRef.current.getRowsCount();
  
  return (
    <Box sx={{ 
      padding: '10px', 
      display: 'flex', 
      justifyContent: 'flex-end',
      marginRight: '20px'
    }}>
      {t('dashboard.total')}: {rowCount} {t('dashboard.results')}
    </Box>
  );
};

const DescriptionCellRenderer = React.memo((props: GridRenderCellParams) => {
  const textRef = React.useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = React.useState(false);

  React.useEffect(() => {
    if (textRef.current) {
      setIsOverflowed(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [props.value]);

  return (
    <Tooltip title={props.value || ''} placement="top-start" disableHoverListener={!isOverflowed}>
      <div style={{ width: '100%', maxWidth: '100%' }}>
        <S.BoxStyled 
          ref={textRef}
          sx={combineStyles(
            gridStyles.cell.base, 
            gridStyles.cell.text,
          )}
        >
          {props.value || ''}
        </S.BoxStyled>
      </div>
    </Tooltip>
  );
});

DescriptionCellRenderer.displayName = 'DescriptionCellRenderer';

const ProjectCellRenderer = React.memo((props: GridRenderCellParams) => {
  const textRef = React.useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = React.useState(false);

  React.useEffect(() => {
    if (textRef.current) {
      setIsOverflowed(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [props.value]);

  return (
    <Tooltip title={props.value} placement="top-start" disableHoverListener={!isOverflowed}>
      <div style={{ width: '100%' }}>
        <S.BoxStyled 
          ref={textRef}
          sx={combineStyles(gridStyles.cell.base, gridStyles.cell.text)}
        >
          {props.value}
        </S.BoxStyled>
      </div>
    </Tooltip>
  );
});

ProjectCellRenderer.displayName = 'ProjectCellRenderer';

const Dashboard: React.FC = () => {
  const { categories, loading, setCurrentCategory, currentCategory } = useCategories();
  const { stages, setStages, currentStage, setCurrentStage } = useStages();
  const [projectsByCategoryAndStage, setProjectsByCategoryAndStage] = React.useState<Record<string, Projects[]>>({});
  const [rows, setRows] = React.useState<Row[]>([]);
  const [filteredRows, setFilteredRows] = React.useState<Row[]>([]);
  const [columns, setColumns] = React.useState<Column[]>([])
  const [tableLoading, setTableLoading] = React.useState(false);
  const [isFiltering, setIsFiltering] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const onTextFilterChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const lowercaseValue = value.toLowerCase();

    if (!value && filteredRows.length) setFilteredRows([]);
    const filteredRowsArray = rows.filter(row => row.projects.toLowerCase().includes(lowercaseValue) || (row.description && row.description.toLowerCase().includes(lowercaseValue)));  // Convert row.projects to lowercase before comparing
    setFilteredRows(filteredRowsArray);
    setIsFiltering(!!value);
  }
  const handleCategoryChange = async (event: SelectChangeEvent) => {
    const category = event.target.value;
    setCurrentCategory(category);
    let stage = state.currentStage;

    const newStages = await StagesService.get(category);

    // Assuming newStages has a default stage, set it to currentStage.
    if (newStages && newStages.length > 0) {
      setStages(newStages);

      let checkIfProd = newStages.find(item => item.value === "prod");
      let checkIfStageExists = newStages.find(item => item.value === currentStage);

      if (checkIfStageExists) {
        stage = currentStage;
      } else if (checkIfProd == undefined) {
        // Set the first stage as default.
        stage = newStages[0].value;
      } else {
        stage = "prod";
      }
    }

    setCurrentStage(stage);
    dispatch({ type: 'SET_STAGE', payload: stage });
    dispatch({ type: 'SET_CATEGORY', payload: category });
  };

  const handleStageChange = async (event: SelectChangeEvent) => {
    const stage = event.target.value;

    setCurrentStage(stage);
    dispatch({ type: 'SET_STAGE', payload: stage });
  };

  const buildColorRows = React.useCallback(async (rows: Row[]) => {
    const productsDetails = await ProductDetailsService.get();
    return rows.flatMap(row => Object
      .entries(row)
      .filter(([key]) => key !== 'id' && key !== 'projects' && key !== 'lastModified' && key !== 'description' && key !== 'lastEditFrom' && key !== 'lastEditAt'))
      .reduce<ColorRow>((acc, [product, productVersion]) => {
        const version = getCycle(productVersion)!;
        const sanitizedVersion = (product === "laravel" || product === 'nodejs') ? version.split('.')[0] : sanitizeProductVersion(version);
        const productStatus = productsDetails[product] ? productsDetails[product][sanitizedVersion ?? ""] : undefined;
        return ({
          ...acc,
          [productVersion]: checkProductsReleases({ productStatus, product, version })
        });
      }, {});
  }, [])

  const versionSort = (v1: string, v2: string): number => {
    // Extract version numbers from the input strings
    const extractVersion = (version: string) => version.split('|').pop() || "";
    const parts1 = extractVersion(v1).split('.').map(Number);
    const parts2 = extractVersion(v2).split('.').map(Number);

    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
      const num1 = parts1[i] || 0;
      const num2 = parts2[i] || 0;
      if (num1 !== num2) {
        return num1 - num2;
      }
    }
    return 0;
  };

  // TODO - Refactor this function so we check stage also
  const buildTableContent = React.useCallback(async (category: string, stage: string) => {
    const key = `${category}-${stage}`;

    setTableLoading(true);

    const projectsFromRequest = await ProjectsService.get(category, stage);
    if (!projectsFromRequest) return;
    const projectsArray = Object.values(projectsFromRequest).flat();
    setProjectsByCategoryAndStage(prev => ({ ...prev, [key]: projectsArray }));

    const columns = projectsFromRequest[category]

    const productColumns = columns
      .flatMap(({ products }) => products)
      .map(({ name }) => name)

    const rows = projectsFromRequest[category]

    const productRows = rows
      .map(({ products, domain, lastModified, description, lastEditFrom, lastEditAt }) => {
        const rowData = products.reduce((acc, { name, cycle }) => {
          const key = buildKey(name, cycle);
          // console.log('buildKey output:', { name, cycle, key });
          return { 
            ...acc, 
            [name]: key, 
            id: `${cycle}${domain}${name}` 
          };
        }, { 
          id: domain, 
          projects: domain, 
          lastModified, 
          description, 
          lastEditFrom, 
          lastEditAt 
        });
        return rowData;
      });

    const getColorByProductVersion = await buildColorRows(productRows);

    const versionedProducts = ['laravel', 'nodejs', 'java', 'php', 'wordpress']; // Add all relevant products here


    const filteredProductColumns = [...new Set(productColumns)]
      .map((name): Column => ({
        field: name,
        headerName: name.toUpperCase(),
        minWidth: 180,
        editable: false,
        headerAlign: 'center' as const,
        align: 'center' as const,
        sortComparator: versionedProducts.includes(name.toLowerCase()) ? versionSort : undefined,
        renderCell: (params: GridRenderCellParams) => {
          const version = getCycle(params.value);
          return version ? (
            <S.BoxStyled sx={combineStyles(gridStyles.cell.base, gridStyles.cell.version)}>
              <S.ChipStyled 
                label={version} 
                color={getColorByProductVersion[params.value]}
              />
            </S.BoxStyled>
          ) : (<></>);
        }
      }))

    // console.log(filteredProductColumns);

    const localeStringOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    };

    PROJECT_COLUMN.headerName = t('dashboard.header.projects');
    const LAST_MODIFIED_COLUMN: Column = { 
      field: "lastModified", 
      headerName: t('dashboard.header.lastDeployment'), 
      minWidth: 230,
      flex: 0,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <S.BoxStyled sx={gridStyles.cell.base}>
          {new Date(params.value).toLocaleString(i18n.language, localeStringOptions)}
        </S.BoxStyled>
      )
    };
    setColumns([PROJECT_COLUMN, DESCRIPTION_COLUMN, LAST_MODIFIED_COLUMN].concat(filteredProductColumns).concat([LAST_EDIT_FROM_COLUMN, LAST_EDIT_AT_COLUMN]));
    setRows(productRows);
    setTableLoading(false);
  }, [t]);

  // In your Dashboard component, add this new handler:
  const handleCellEditCommit = React.useCallback(
    async (params: any) => {
      if (params.field !== 'description') return;

      const { id, value } = params;
      const project = rows.find(row => row.id === id);

      if (!project) return;

      // Check if the value has actually changed
      if (project.description === value) {
        return; // Exit early if no changes were made
      }

      setTableLoading(true); // Add loading state while updating

      try {
        const response = await updateProjectDescription(currentCategory, currentStage, project.projects, value);

        // Verify the response
        if (response && response.body) {
          // Update local state with all updated fields from response
          setRows(prevRows =>
            prevRows.map(row =>
              row.id === id ? {
                ...row,
                description: value,
                lastEditFrom: response.body.lastEditFrom,
                lastEditAt: response.body.lastEditAt
              } : row
            )
          );
          toast.success(t('dashboard.descriptionUpdateSuccess'));
        } else {
          // If response is not as expected, show error
          throw new Error('Invalid server response');
        }
      } catch (error) {
        console.error('Failed to update description:', error);
        toast.error(t('dashboard.descriptionUpdateError'));
        
        // Revert the change in the UI
        setRows(prevRows =>
          prevRows.map(row =>
            row.id === id ? {
              ...row,
              description: project.description // Revert to original description
            } : row
          )
        );
      } finally {
        setTableLoading(false); // Remove loading state
      }
    },
    [currentCategory, currentStage, rows, t]
  );
  const onRowClick = (field: string, row: Record<string, string>) => {
    if (!row[field]) return;

    if (field === "projects") {
      return;
    }
    if (field === "lastModified") {
      return;
    }
    if (field === "description") {
      return;
    }
    return navigate(`/project/${currentCategory}/${currentStage}/${row["projects"]}`);
  }

  React.useEffect(() => {
    const lowercaseValue = searchText.toLowerCase();

    if (!searchText && filteredRows.length) setFilteredRows([]);
    const filteredRowsArray = rows.filter(row => row.projects.toLowerCase().includes(lowercaseValue) || (row.description && row.description.toLowerCase().includes(lowercaseValue)));  // Convert row.projects to lowercase before comparing
    setFilteredRows(filteredRowsArray);
    setIsFiltering(!!searchText);
  }, [searchText, rows]);

  React.useEffect(() => {
    let category = currentCategory;
    let stage = currentStage;

    if (currentCategory === "") {
      category = "wordpress";
      dispatch({ type: 'SET_CATEGORY', payload: category });
    }

    if (currentStage === "") {
      stage = "prod";
      dispatch({ type: 'SET_STAGE', payload: stage });
    }

    buildTableContent(category, stage);
  }, [state.currentStage, state.currentCategory])

  // Add effect to update columns when language changes
  React.useEffect(() => {
    if (currentCategory && currentStage) {
      buildTableContent(currentCategory, currentStage);
    }
  }, [i18n.language]); // Add i18n.language as a dependency

  const localeStringOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };

  const DESCRIPTION_COLUMN: Column = {
    field: "description",
    headerName: t('dashboard.header.description'),
    minWidth: 250,
    maxWidth: 800,
    flex: 1,
    editable: true,
    renderCell: (params: GridRenderCellParams) => <DescriptionCellRenderer {...params} />
  };

  const LAST_EDIT_FROM_COLUMN: Column = { 
    field: "lastEditFrom", 
    headerName: t('dashboard.header.lastEditFrom'), 
    minWidth: 200,
    editable: false,
    renderCell: (params: GridRenderCellParams) => <S.BoxStyled>{params.value}</S.BoxStyled> 
  };

  const LAST_EDIT_AT_COLUMN: Column = {
    field: "lastEditAt",
    headerName: t('dashboard.header.lastEditAt'),
    minWidth: 200,
    editable: false,
    renderCell: (params: GridRenderCellParams) => {
      if (!params.value) return <S.BoxStyled></S.BoxStyled>;
      try {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) return <S.BoxStyled></S.BoxStyled>;

        return <S.BoxStyled>
          {date.toLocaleString(i18n.language, localeStringOptions)}
        </S.BoxStyled>;
      } catch {
        return <S.BoxStyled></S.BoxStyled>;
      }
    }
  };

  const PROJECT_COLUMN: Column = { 
    field: "projects", 
    headerName: t('dashboard.header.projects'), 
    minWidth: 300,
    maxWidth: 450,
    flex: 1,
    editable: false, 
    renderCell: (params: GridRenderCellParams) => <ProjectCellRenderer {...params} />
  };

  return (
    <S.Wrapper>
      {tableLoading && <Loading />}
      <S.Header style={{ margin: "24px" }} >
        <FormControl style={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-label-categories">{t('dashboard.categories')}</InputLabel>
          <Select
            options={categories}
            label={t('dashboard.categories')}
            handleChange={handleCategoryChange}
            value={currentCategory}
            loading={loading}
          />
        </FormControl>
        <FormControl style={{ width: "200px", marginLeft: "1rem" }}>
          <InputLabel id="demo-simple-select-label-stages">{t('dashboard.stages')}</InputLabel>
          <Select
            options={stages}
            label={t('dashboard.stages')}
            handleChange={handleStageChange}
            value={currentStage}
            loading={loading}
          />
        </FormControl>
        <TextField
          style={{ marginLeft: "2rem", flex: 1 }}
          id="outlined-basic"
          label={t('dashboard.search')}
          variant="outlined"
          value={searchText}
          onChange={(event) => {
            onTextFilterChange({ target: { value: event.target.value } } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
            setSearchText(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search"
                  onClick={() => {
                    setSearchText("");
                    onTextFilterChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          aria-label="refresh"
          variant="contained"
          style={{ marginLeft: "2rem" }}
          onClick={() => {
            buildTableContent(currentCategory, currentStage);
          }}
        >
          <RefreshIcon />
        </Button>
      </S.Header>
      <S.WrapperTable style={{ padding: "0 24px 24px 24px" }}>
        <DataGrid
          rows={isFiltering ? filteredRows : rows}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                lastEditFrom: false,
                lastEditAt: false,
              },
            },
          }}
          onCellClick={({ field, row }) => onRowClick(field, row)}
          slots={{ 
            toolbar: CustomToolbar,
            footer: CustomFooter
          }}
          hideFooterPagination
          processRowUpdate={(newRow, oldRow) => {
            handleCellEditCommit({
              field: 'description',
              id: newRow.id,
              value: newRow.description
            });
            return newRow;
          }}
          sx={gridStyles.grid.root}
          localeText={{
            toolbarColumns: t('dashboard.toolbar.columns'),
            toolbarExport: t('dashboard.toolbar.export'),
          }}
          disableVirtualization
        />
      </S.WrapperTable>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </S.Wrapper>
  );
};

export default Dashboard;