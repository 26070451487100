import { styled } from "@stitches/react";
import { Box, Button, Chip, Typography } from "@mui/material";
import { SxProps } from '@mui/material';

export const TitleStyled = styled(Typography, {
  fontSize: "24px",
  marginBottom: "16px",
  color: "Black",
  textAlign: "center"
});

export const ButtonStyled = styled(Button, {
  backgroundColor: "Blue",
  color: "White",
  borderRadius: "9999px",
  fontSize: "12px",
  padding: "8px",
});

export const Wrapper = styled('div',{  
  position: 'relative',
  width: "95vw",
  overflow: 'hidden',  
  background: "#fff",
  boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px",
  borderRadius: "0.3rem",
  padding: "1rem 1rem 1rem 1rem",
  display: 'flex',
  flexDirection: 'column',
  flex: 1
})

export const Header = styled('div',{
  marginTop: '1rem',
  display: 'flex'
})

export const BoxStyled = styled(Box, {
  width: '100%',
  cursor: 'pointer',
  borderRadius: '3px',
  padding: '10px',
})

export const ChipStyled = styled(Chip, {
  display: 'flex',
  transition: "0.9s ease-in",  
  width: '90px',
  cursor: 'pointer !important',
  font: 'normal normal bold 12px/16px Roboto',
})

export const WrapperTable = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto',
})

export const gridStyles = {
  cell: {
    base: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 10px'
    },
    text: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      maxWidth: '100%',
      display: 'block'
    },
    version: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& .MuiChip-root': {
        height: '32px',
        borderRadius: '16px',
        minWidth: 'auto',
        maxWidth: 'none'
      },
      '& .MuiChip-label': {
        fontSize: '1rem',
        padding: '0 12px',
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'visible',
        textOverflow: 'unset',
        display: 'block',
        width: 'auto'
      }
    }
  },
  grid: {
    root: {
      '& .MuiDataGrid-columnHeader': {
        padding: '0 20px'
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
      },
      '& .MuiDataGrid-cell--editable': {
        cursor: 'text',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.02)'
        },
        '&.MuiDataGrid-cell--editing': {
          backgroundColor: '#ffffff !important'
        }
      },
      width: 'auto',
      minWidth: '100%',
      height: 'calc(100% - 40px)',
      overflowX: 'auto',
      // Hide scrollbar buttons
      '& ::-webkit-scrollbar-button:horizontal': {
        display: 'none'
      },
      '& ::-webkit-scrollbar-button:vertical': {
        display: 'none'
      },
      // Firefox scrollbar styling
      scrollbarWidth: 'thin',
      // IE and Edge
      msOverflowStyle: 'none',
      '& *': {
        scrollbarWidth: 'thin',
        msOverflowStyle: 'none'
      }
    }
  }
} as const;

export const combineStyles = (...styles: SxProps[]): SxProps => {
  return styles.reduce((acc, style) => ({ ...acc, ...style }), {});
};